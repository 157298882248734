import React from "react"
import Layout from "../components/layout"

export default function KinderliedjesFaq() {
  return (
    <Layout>
      <article class="content-item page">
        <h1>kinderliedjes Apple iOs: veel gestelde vragen</h1>
        <p>
          <strong>De melodietjes doen het niet?</strong>
        </p>
        <p>
          Wat vervelend om te horen dat de melodietjes het niet doen.&nbsp;De
          app wordt&nbsp;zorgvuldig getest door ons en door Apple voordat de
          applicatie aangeboden mag worden in de app store. Staat uw geluid aan?
          Doen andere apps het wel? Indien u bent overgegaan tot een aankoop van
          de melodietjes dan kunnen er twee oorzaken zijn. <br />
          &nbsp;
          <br />1 Er is iets misgegaan met de betaling. <br />2 Er is iets
          misgegaan vanuit Apple met de terugmelding van uw betaling naar de
          app. <br />
          &nbsp;
          <br />
          In beide gevallen kunt u dit oplossen door nogmaals de aankoop te
          initiëren. In geval 1 wordt u gevraagd de aankoop te bevestigen,
          vervolgens komt er een terugmelding naar de applicatie, waardoor de
          melodietjes beschikbaar komen.
          <br />
          &nbsp;
          <br />
          In geval 2 zal er een melding komen dat u de aankoop al gedaan heeft.
          Daarna zal evengoed vanuit Apple opnieuw een terugmelding naar de
          applicatie komen waardoor de melodietjes alsnog beschikbaar komen. In
          beide gevallen betaalt u maximaal één keer. U kunt dit herhalen als
          het niet in één keer lukt.
        </p>
        <p>
          De volledige informatie over aankopen in programma’s kunt u vinden op
          de apple support site:
          <br />
          <a href="http://support.apple.com/kb/HT4009?viewlocale=nl_NL&amp;locale=nl_NL">
            http://support.apple.com/kb/HT4009?viewlocale=nl_NL&amp;locale=nl_NL
          </a>
          <br />
          &nbsp;
          <br />
          Hierin staat o.a. het volgende:
          <br />
          Niet-uitputtende aankopen binnen een programma kunnen opnieuw gratis
          worden gedownload. Enkele voorbeelden: bonusniveaus in games, een
          stadskaart/gids, dagelijks nieuwsabonnement of andere herhaalde
          services. <br />
          Als u een aankoop in een programma opnieuw wilt downloaden, moet u die
          downloaden in het geïnstalleerde programma met dezelfde iTunes
          Store-accountnaam die u hebt gebruikt voor de oorspronkelijke aankoop
          in het programma.
        </p>
        <p>
          <strong>
            <br />
            Ik heb een nieuwe iphone of&nbsp;ik heb ook een ipad, waarom moet ik
            dubbel betalen?
          </strong>
        </p>
        <p>
          Informatie over aankopen in programma’s kunt u vinden op de apple
          support site:
          <br />
          <a href="http://support.apple.com/kb/HT4009?viewlocale=nl_NL&amp;locale=nl_NL">
            http://support.apple.com/kb/HT4009?viewlocale=nl_NL&amp;locale=nl_NL
          </a>
        </p>
        <p>Hierin staat o.a. het volgende:</p>
        <ul>
          <li>
            Niet-uitputtende aankopen binnen een programma kunnen opnieuw gratis
            worden gedownload. Enkele voorbeelden: bonusniveaus in games, een
            stadskaart/gids, dagelijks nieuwsabonnement of andere herhaalde
            services.
          </li>
          <li>
            Als u een aankoop in een programma opnieuw wilt downloaden, moet u
            die downloaden in het geïnstalleerde programma met dezelfde iTunes
            Store-accountnaam die u hebt gebruikt voor de oorspronkelijke
            aankoop in het programma.
          </li>
        </ul>
        <p>&nbsp;</p>
        <p>
          De melodieën zijn een “Niet-uitputtende aankoop”. Het beleid en
          werking van in app aankopen hebben wij als software leverancier niet
          in de hand, maar gelukkig voor u, kunt u in uw geval de melodieën
          nogmaals gratis “aankopen”, mits u gebruik maakt van hetzelfde itunes
          store account.
        </p>
      </article>
    </Layout>
  )
}
